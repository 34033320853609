import { createRouter, createWebHistory } from 'vue-router'
import index from '../views/index.vue'

const routes = [
  { path: '/', redirect: '/index' },
  { path: '/index', name: 'index', component: index },
  { path: '/:pathMatch(.*)*', redirect: '/index' } // 添加重定向规则

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
