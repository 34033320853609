<template>
    <div class="screen1 page">
        <div class="nav-box flex-between">
            <div>寄快递 发物流丨就上人人寄</div>
            <div class="nav-btn-box flex-between">
                <div class="nav-btn">
                    <span @click="handleDom(1)">关于我们</span>
                    <span @click="handleDom(2)">服务优势</span>
                    <span @click="handleDom(3)">联系我们</span>
                </div>
                <div class="qrCode"><a-button class="nav-login" type="primary" @click="handleOpen1" danger>立即寄件</a-button>
                    <div class="codeBg" v-if="isShow1">
                        <img src="../assets/images/qrcode1_3.png" />
                    </div>
                </div>

            </div>
        </div>
        <div class="content flex-between">
            <div class="content-title">
                <div> 寄快递发物流 | 就上人人寄</div>
                <div>AI自动多渠道比价，寄快递发物流，超能帮您省，一单也是特惠价！</div>
                <div class="qrCode"><a-button class="nav-login" type="primary" @click="handleOpen2" danger>立即寄件</a-button>
                    <div class="codeBg" v-if="isShow2">
                        <img src="../assets/images/qrcode1_3.png" />
                    </div>
                </div>
            </div>
            <div class="phone-img">
                <img src="../assets/images/index1_1.png" alt="">
                <img src="../assets/images/index1_2.png" alt="">
            </div>
        </div>
    </div>
    <div class="screen2 page">
        <div class="content  flex-center">
            <img class="phone-img" src="../assets/images/about-preview.png" alt="">
            <div class="text-box">
                <div class="title">个人/中小商家寄件的好选择</div>
                <h2>无单量限制，一单也是特惠价<br />支持寄国内，寄大件，寄物流</h2>
                <div class="text">
                    24小时在线下单，快递员上门取件<br />
                    支持全国主流快递物流公司<br />
                    自主灵活选择快递渠道发货，运费低至5元起
                </div>
            </div>
        </div>
    </div>
    <div class="screen3 page" id="mine">
        <div class="content">
            <div class="yibuFrameContent flex-col-center">
                <img src="../assets/images/img1.png" alt="">
                <h2>全国取件</h2>
                <p>支持全国各地上门取件，解决客户异地发快递难题；16:00之前下单，当日取件，16:00之后下单，次日取件；全年无休</p>
            </div>
            <div class="yibuFrameContent flex-col-center">
                <img src="../assets/images/img2.png" alt="">
                <h2>价格优惠</h2>
                <p>平台直接签约快递总部，价格极具竞争力；并通过AI人工智能比价系统，自动推荐市面上价格最优惠的快递</p>
            </div>
            <div class="yibuFrameContent flex-col-center">
                <img src="../assets/images/img3.png" alt="">
                <h2>下单便捷</h2>
                <p>支持微信公众号和微信小程序下单，操作简单，便捷易用，快递、同城急送、大件物流均可轻松搞定</p>
            </div>
            <div class="yibuFrameContent flex-col-center">
                <img src="../assets/images/img4.png" alt="">
                <h2>客户服务</h2>
                <p>客服在线时间9:00--19:00，全年无休，随时响应并处理客户寄快递过程中遇到的问题，做到专业、细致、耐心</p>
            </div>
            <div class="yibuFrameContent flex-col-center">
                <img src="../assets/images/img5.png" alt="">
                <h2>安全保障</h2>
                <p>直接对接快递公司总部，所有包裹均为快递公司小哥直接取件派件，物流轨迹全程可追踪，安全可靠</p>
            </div>
            <div class="yibuFrameContent flex-col-center">
                <img src="../assets/images/img6.png" alt="">
                <h2>选择多样</h2>
                <p>平台对接市面上所有主流快递公司，不仅价格优惠，还有丰富的资源可供选择，能满足您的多样化需求</p>
            </div>
        </div>
    </div>
    <div class="screen4 page">
        <div class="content">
            <h2>我们的服务能力</h2>
            <div class="power-list">
                <div class="power-item flex-col-center">
                    <img src="../assets/images/power1.png" />
                    <h4>当日取件</h4>
                    <p>在线下单，<br />全国省市区上门取件，一般当天<br />取件时效48小时</p>
                </div>
                <div class="power-item flex-col-center">
                    <img src="../assets/images/power2.png" />
                    <h4>运输时效</h4>
                    <p>国内主流快递品牌运输<br />支持圆通、申通、韵达、极兔、德邦、顺丰等一线<br />品牌快递，时效有保障！</p>
                </div>
                <div class="power-item flex-col-center">
                    <img src="../assets/images/power3.png" />
                    <h4>推广奖励</h4>
                    <p>分享好友<br />分享好友寄快递，单单得奖励！</p>
                </div>
            </div>
        </div>
    </div>
    <div class="screen5 page" id="advantage">
        <div class="content">
            <h2>我们的优势</h2>
            <div class="feature-box flex-between">
                <div class="feature-item flex-col-left">
                    <div class="iconfont">
                        <img src="../assets/images/feature1.png" />
                    </div>
                    <h4>寄快递</h4>
                    <p>1-30kg小件快递<br />可自主选择快递渠道智能比价<br />低至5元起寄！</p>
                </div>
                <div class="feature-item flex-col-left">
                    <div class="iconfont">
                        <img src="../assets/images/feature2.png" />
                    </div>
                    <h4>寄物流</h4>
                    <p>20-5000Kg 大件物流<br />顺心捷达、跨越、德邦、京东物流、中通全渠道覆盖
                        <br />当天上门取件，时效快，价格优!
                    </p>
                </div>
                <div class="feature-item flex-col-left">
                    <div class="iconfont">
                        <img src="../assets/images/feature4.png" />
                    </div>
                    <h4>推广奖励</h4>
                    <p>一件也是特惠价<br />分享好友寄件，奖励多多<br />一次分享，永久绑定，搭建管道收益</p>
                </div>
            </div>
        </div>
    </div>
    <div class="footer " id="about">
        <div class="flex-between">
            <div class="content">
                <h4>联系我们</h4>
                <p>如有任何疑问请联系我们，我们竭诚为您服务<br />
                    客服时间 周一至周日 9：00 至 19：00<br />
                    联系邮箱 bd@cpskd.cn</p>
            </div>
            <div class="flex">
                <div class="code flex-col-center">
                    <img src="../assets/images/qrcode1.jpg" />
                    <p>公众号</p>
                </div>
                <div class="code flex-col-center">
                    <img src="../assets/images/qrcode1_2.png" />
                    <p>小程序</p>
                </div>
                <div class="code flex-col-center">
                    <img src="../assets/images/qrcode1_3.png" />
                    <p>微信</p>
                </div>
            </div>
        </div>
        <a-divider />
        <div class="footer-text">湖南耕耘未来科技有限公司 版权所有 Copyright © 2023 <a href="https://beian.miit.gov.cn"
                target="_blank">湘ICP备2023025772号-2</a></div>
    </div>
</template>
  
<script setup>
import { ref, watch } from 'vue'

const isShow1 = ref(false)
const isShow2 = ref(false)
const flg = ref(true)
const showBtn = ref(false)

const handleDom = (index) => {
    if (index === 1) {
        document.querySelector("#mine").scrollIntoView(true);
    } else if (index === 2) {
        document.querySelector("#advantage").scrollIntoView(true);
    } else if (index === 3) {
        document.querySelector("#about").scrollIntoView(true);
    }
}
const handleOpen1 = () => {
    isShow1.value = true
}
const handleOpen2 = () => {
    isShow2.value = !isShow2.value
}
</script>
<style scoped lang="scss">
@import url('../assets/style/index.scss');

.footer {
    padding: 40px 180px;
    background: #f0f0f0;
    width: 100%;

    .footer-text {
        text-align: center;
        font-size: 14px;
        color: #666;
    }

    .content {
        h4 {
            font-size: 24px;
        }

        p {
            margin-top: 20px;
            font-size: 14px;
            color: #222222;
            line-height: 28px;
        }
    }

    .code {
        margin-left: 30px;

        img {
            width: 80px;
        }

        p {
            font-size: 14px;
            color: #222222;
            line-height: 28px;
        }
    }
}

.screen5 {
    width: 100%;
    background: url('../assets/images/bg4.png') no-repeat;
    background-size: 100% auto;

    .content {
        padding: 80px 0;

        h2 {
            text-align: center;
            font-size: 40px;
        }

        .feature-box {
            margin-top: 60px;

            .feature-item {
                background-color: #fff;
                border-radius: 12px;
                width: 32%;
                height: 280px;
                border: 1px solid #ddd;
                box-shadow: 0px 20px 20px 0 rgba(0, 0, 0, 0.1);
                padding: 24px 20px;

                .iconfont {
                    width: 50px;
                    height: 50px;
                    border: 1px solid #2c3cfe;
                    border-radius: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba($color: #e1e4ff, $alpha: .6);
                }

                img {
                    width: 25px;

                }

                h4 {
                    margin: 20px 0 30px;
                    font-size: 24px;
                }

                p {
                    font-size: 14px;
                    line-height: 22px;
                    color: #222222;

                }
            }
        }
    }
}

.screen4 {
    width: 100%;
    background: url('../assets/images/bg3.png') no-repeat;
    background-size: 100% auto;

    .content {
        padding: 80px 0;

        h2 {
            text-align: center;
            font-size: 40px;
        }
    }

    .power-list {
        display: flex;
        justify-content: space-between;
        margin-top: 60px;

        .power-item {

            img {
                width: 160px;
            }

            h4 {
                margin: 20px 0 40px;
                font-size: 24px;
            }

            p {
                font-size: 14px;
                line-height: 22px;
                text-align: center;
                color: #222222;
            }
        }
    }

}

.screen3 {
    width: 100%;
    height: auto;
    background: url('../assets/images/bg2.png') no-repeat;
    background-size: 100% auto;
    background-position: right bottom;
    background-color: #f5f7fe;

    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px 0 50px 0;


        .yibuFrameContent {
            width: 32%;
            margin-top: 20px;
            padding: 30px;
            box-sizing: border-box;
            background: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(10px);
            border-radius: 12px;

            img {
                width: 60px;
                height: 60px;
            }

            h2 {
                font-size: 36px;
                margin: 20px 0 30px 0;
            }


            p {
                font-size: 14px;
                line-height: 24px;
                text-align: center;
            }
        }
    }
}

.screen2 {
    width: 100%;
    background: url('../assets/images/bg4.png') no-repeat;
    background-size: 100% auto;

    .content {
        padding: 80px 0;
        box-sizing: border-box;

    }

    .phone-img {
        width: 240px;
        height: 462px;
    }

    .text-box {
        margin-left: 160px;

        .title {
            font-size: 16px;
        }

        h2 {
            font-size: 40px;
            margin: 40px 0;
        }

        .text {
            font-size: 16px;
            line-height: 32px;
        }
    }
}

.screen1 {
    width: 100%;
    background: url('../assets/images/bg6.png') no-repeat;
    background-size: 100% auto;

    .content {
        margin-top: 60px;

        .content-title {
            width: 50%;

            div {
                font-size: 36px;
                margin-bottom: 40px;
            }
        }


    }

    .phone-img {
        width: 49%;
        margin-left: 1%;

        img {
            width: 45%;
            margin-left: 20px;
        }

        img:nth-child(2) {
            margin-top: 80px;
        }
    }

    .nav-login {
        width: 120px;
        height: 38px;
        background-color: #2c3cfe;
        border-radius: 20px;
    }

    .nav-box {
        padding-top: 40px;

        .logo {
            width: 80px;
        }

        .nav-btn-box {


            .nav-btn {
                span {
                    font-size: 16px;
                    margin-right: 40px;
                }


            }
        }


    }
}

.qrCode {
    position: relative;

    .codeBg {
        width: 230px;
        height: 260px;
        position: absolute;
        top: 0;
        left: -50px;
        background: url('../assets/images/codeBg.png');
        background-size: 100% 100%;

        img {
            width: 113px;
            height: 113px;
            margin: auto;
            display: block;
            margin-top: 72px;
        }
    }
}

.page {
    padding: 0 180px;
}
</style>