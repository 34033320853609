<template>
    <div></div>
    <pcView v-if="!_isMobile" />
    <phoneView v-if="_isMobile" />
</template>

<script setup >
import pcView from '@/components/pc.vue'
import phoneView from '@/components/phone.vue'

const _isMobile = /Android|iPhone|iPod|BlackBerry/i.test(window.navigator.userAgent)

</script>

<style scoped></style>